<template>
  <div>
  <b-card>

    <h4 v-if="currentObject.event_name" class="mb-2">
      Additional Data Report <a :href="'/events/' + currentObject.event_id + '/view'">({{ currentObject.event_name }})</a>
    </h4>
    
    <div class="row">
      <div class="col-4">
          <v-select
                v-model="selectedField"
                :options="currentObject.additional_fields"
                :reduce="val => val.id"
                :clearable="false"
                input-id="fields_id"
                placeholder="Pick a field"
              />
            
      </div>
      <div class="col-2">
        <b-button
              variant="primary"
              @click="loadData()"
              :disabled="isLoading"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span>{{ (isLoading) ? "Loading..." : "Show" }}</span>
            </b-button>
      </div>
    </div>
    
  </b-card>

  <b-card v-for="datum in dataReport.data" :key="datum.id">
    <h4 class="mb-2">
      {{ datum.attribute }}
    </h4>
    <br>
    <vue-good-table
      :columns="columns"
      :rows="datum.data"
      :sort-options="{
        enabled: true,
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      
    ></vue-good-table>
  </b-card>
</div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BRow, BCol
} from 'bootstrap-vue'
import router from '@/router'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BRow,
    BCol
  },
  data() {
    return {
      isLoading: false,
      currentObject: {
        user_id: -1,
        event_id: -1,
        event_name: null,
        event_code: null,
        additional_fields: []
      },
      selectedField: null,
      dataReport:{},
      columns: [
        {
            label: 'Name',
            field: 'name'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Company',
          field: 'company'
        },
        {
          label: 'Phone',
          field: 'phone'
        },
        {
          label: 'Answer',
          field: 'value'
        },
      ]
    }
  },
  props:{

  },
  computed: {
  },
  methods: {
    
    fetchEvent() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/events/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.currentObject.event_name =  currentData.name
            currentData.fields.forEach((item) => {
              item.label = item.name;
              this.currentObject.additional_fields.push(item)
            })
          }
     })
    },
   
    loadData(){
      const id = router.currentRoute.params.id
      this.isLoading = true
      const fieldId = this.selectedField
      
      //loading data
      this.$http.get('/admin/v1/events/' + id + '/additionalReport/' + fieldId)
      .then(res => {
        const data = res.data
        const message = data.message
        if(!data.status) {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
        }else{
          let data = res.data.data
          let att = data.attribute
          let attValues = []
          let dataList = []
          data.attributeValue.forEach((item)=>{
            attValues.push(item)
          })
          data.list.forEach((item)=>{
            dataList.push(item)
          })

          if(attValues.length == 0){
            //single att
            let obj = {
              data:[
                {
                  attribute: att,
                  data:dataList
                }
              ]
            }
            // console.log(obj)
            this.dataReport = obj
            
          }else{
            //multiple options
            let d = []
            attValues.forEach((attV)=>{
              let list = []
              dataList.forEach((item)=>{
                if(attV == item.value){
                  list.push(item)
                  // dataList.splice(index,1)
                }
              })
              let objAtt = {
                attribute: att+" - "+attV,
                data:list
              }
              d.push(objAtt)
            })
            let obj = {
              data:d
            }

            this.dataReport = obj
            // console.log(this.dataReport)
          }

          

        }
        this.isLoading = false
      })
    }
    
  },
  created() {
    this.fetchEvent()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
